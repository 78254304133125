@import '../../../../colors.scss';

.descriptor-box-editor-wrapper {
    width: min(100%, 800px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-self: center;
    align-items: center;
    height: 100%;
}

.more-descriptors-icon {
    color: $default;
    margin: 0 0 2px 10px;
}

.more-descriptors-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}