.edit-descriptor-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    margin: 10px 0;
}

.descriptor-name-dropdown .cfa-dropdown-display-text {
    font-family: Apercu-regular;
}

.link-text-field {
    margin-bottom: 10px;
}