@import '../../../../colors.scss';

.add-descriptor-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.add-descriptor-icon {
    color: $navy;
    margin: 0 10px 2px 0;
}

.new-descriptor-icon {
    color: $navy;
}