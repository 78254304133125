@import '../../../../colors.scss';

.descriptor-box {
    min-width: 500px;
    max-height: 470px;
    width: 100%;
    background-color: $white;
    position: relative;
    padding: 5px 30px;
    text-align: center;
    border: 1px solid $faint-gray;
}

.descriptor-box-edit-icon {
    position: absolute;
    right: 0;
    margin-right: 5px;
    overflow: visible;
    cursor: pointer;
}

.descriptor-link {
    font-family: inherit;
    color: inherit;
    &:hover {
        color: $primary;
        text-decoration: none;
    }
}